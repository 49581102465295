@font-face {
  font-family: "ClanOT";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/FF-Clan-OT-Book.otf) format("opentype");
}

@font-face {
  font-family: "ClanOT";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/FF-Clan-OT-News.otf) format("opentype");
}

@font-face {
  font-family: "ClanOT";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/FF-Clan-OT-Medium.otf) format("opentype");
}
