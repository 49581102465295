@function clampPreferred($minWidthPx, $maxWidthPx, $minFontSize, $maxFontSize) {
  $minWidth: $minWidthPx / 16;
  $maxWidth: $maxWidthPx / 16;

  $slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
  $yAxisIntersection: -$minWidth * $slope + $minFontSize;

  $preferred: #{$yAxisIntersection * 1rem} + " + " + #{$slope * 100vw};
  $minSize: $minFontSize * 1rem;
  $maxSize: $maxFontSize * 1rem;

  @return clamp($minSize, $preferred, $maxSize);
}

@function clampPreferredPixels($minWidthPx, $maxWidthPx, $minFontSizePx, $maxFontSizePx) {
  $minWidth: $minWidthPx / 16;
  $maxWidth: $maxWidthPx / 16;
  $minFontSize: $minFontSizePx / 16;
  $maxFontSize: $maxFontSizePx / 16;

  $slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
  $yAxisIntersection: -$minWidth * $slope + $minFontSize;

  $preferred: #{$yAxisIntersection * 1rem} + " + " + #{$slope * 100vw};
  $minSize: $minFontSize * 1rem;
  $maxSize: $maxFontSize * 1rem;

  @return clamp($minSize, $preferred, $maxSize);
}

@function pow($base, $exponent) {
  $result: 1;
  @for $_ from 1 through $exponent {
    $result: $result * $base;
  }
  @return $result;
}

:root {
  --font-primary: ClanOT;

  /* 870px */
  --text-2xl-size: #{clampPreferred(320, 2560, 6.125, 54.375)};

  /* 300px */
  --text-xl-size: #{clampPreferred(320, 2560, 2.8125, 15)};

  /* 140px */
  --text-lg-size: #{clampPreferred(740, 2560, 2.5, 8.75)};

  /* 120px */
  --text-md-size: #{clampPreferred(740, 2560, 2, 4)};

  /* 40px */
  --text-base-size: #{clampPreferred(320, 2560, 1, 2.5)};

  /* 25px */
  --text-sm-size: #{clampPreferred(320, 2560, 0.875, 1.5625)};

  /* 10px */
  --text-xs-size: 0.625rem;

  --space-xl: #{clampPreferredPixels(320, 2560, 50, 120)};
  --space-lg: #{clampPreferredPixels(740, 2560, 25, 140)};
  --space-base: #{clampPreferredPixels(320, 2560, 25, 100)};
  --space-sm: #{clampPreferredPixels(740, 2560, 20, 80)};
  --space-xs: #{clampPreferredPixels(740, 2560, 10, 25)};

  --columns-number-desktop: 12;
  --columns-margin-desktop: 50px;
  --columns-gutter-desktop: 30px;
  --columns-number-tablet: 12;
  --columns-margin-tablet: 30px;
  --columns-gutter-tablet: 20px;
  --columns-number-mobile: 6;
  --columns-margin-mobile: 20px;
  --columns-gutter-mobile: 20px;

  --color-primary: #1d1d1d;
  --color-secondary: #ffffff;
  --color-accent: #d00070;
  --color-neutral: #e5e5e5;
  --color-neutral-light: rgba(211, 211, 211, 0.4);
}
