@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";
@import "./font.css";

@layer base {
  h1,
  .h1 {
    font-size: var(--text-xl-size);
    @apply font-medium font-display my-xl;
  }

  h2,
  .h2 {
    font-size: var(--text-lg-size);
    @apply font-medium font-display;
  }

  h3,
  .h3 {
    font-size: var(--text-md-size);
    @apply font-bold font-display;
  }

  h4,
  .h4 {
    font-size: var(--text-base-size);
    @apply font-bold font-display;
  }

  h5,
  .h5 {
    font-size: var(--text-base-size);
    @apply font-medium;
  }

  h6,
  .h6 {
    font-size: var(--text-sm-size);
    @apply font-normal;
  }

  body,
  .body {
    font-size: var(--text-base-size);
    @apply font-normal font-display text-primary;
  }

  .body2 {
    font-size: var(--text-sm-size);
    @apply font-normal font-display;
  }

  em {
    @apply text-accent font-bold not-italic;
  }

  li {
    @apply ml-10 list-disc;
  }

  .layout {
    @apply grid grid-cols-layout-mobile gap-x-layout-gutter-mobile mx-layout-margin-mobile
    tablet:grid-cols-layout-tablet tablet:gap-x-layout-gutter-mobile tablet:mx-layout-margin-tablet
    desktop:grid-cols-layout-desktop desktop:gap-x-layout-gutter-desktop desktop:mx-layout-margin-desktop;
  }

  .label {
    font-size: var(--text-sm-size);
    line-height: var(--text-sm-line-height);
    @apply font-normal font-display uppercase;
  }

  .big-title,
  .h0 {
    font-size: var(--text-2xl-size);
    line-height: 1;
    @apply font-medium font-display;
  }

  .footer {
    font-size: var(--text-sm-size);
    @apply font-normal font-display;

    a {
      @apply underline;
    }
  }
}

html {
  scroll-behavior: smooth;
  @apply scroll-pt-20 tablet:scroll-pt-28;
}
